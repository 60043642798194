<template>
  <div class="container">
    <el-timeline>
      <el-timeline-item
        v-for="(item, index) in list"
        :key="index"
        :timestamp="item.goTime.substr(0, 10)"
      >
        <div class="tit">
          <section>
            <span
            >目的地：{{
              (item.abroadName ? item.abroadName : "中国") +
              (item.province || "") +
              (item.city || "") +
              (item.district || "") +
              item.aimAddress
            }}</span
          >
          <p>
            交通工具：{{
              item.tripWayId == 2
                ? "飞机"
                : item.tripWayId == 1
                ? "火车"
                : "其他"
            }}
          </p>
          <p>班次：{{ item.tripNo }}</p>
          <p>出行原因：{{ item.tripReason }}</p>
          </section>
          <div style="display: flex; justify-content: space-between">
            <span class="edit" @click="edit(item)">
              <i class="el-icon-edit-outline"></i>
            </span>
            <span class="del" @click="del(item)">
              <i class="el-icon-delete"></i>
            </span>
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import Vue from "vue";
import { tripList, tripDelete } from "@/api/api";

export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let mobile = this.$store.state.userInfo.mobile;
      tripList({ mobile: mobile }).then((res) => {
        this.list = res.data.data;
      });
    },
    //跳转编辑
    edit(item) {
      this.$router.push("/journey?isEdit=true&data=" + JSON.stringify(item));
    },
    // 删除
    del(item) {
      this.$weui.confirm("确认删除行程记录？", {
        title: "提示",
        buttons: [
          {
            label: "取消",
            type: "default",
            onClick: function () {},
          },
          {
            label: "确定",
            type: "primary",
            onClick: () => {
              tripDelete(item.id).then((res) => {
                this.$weui.toast("删除成功", {
                  duration: 1500,
                  className: "custom-classname",
                });
                this.getList();
              });
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.container {
  padding: 30px 20px;
  padding-right: 5px;
}
.tit {
  display: flex;
  justify-content: space-between;
}
.tit span:first-child {
  width: 75%;
}
.tit .edit {
  color: #2f7dcd;
}
.tit .del {
  color: red;
}
.tit div span {
  font-size: 24px;
  width: 50px;
  text-align: center;
}
</style>